body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #121212; /* Dark background */
    color: #ffffff; /* Light text color */
}

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
}

.header {
    color: #ff9800; /* Gradient color 1 */
    background: linear-gradient(to right, #ff9800, #ff5722); /* Gradient effect */
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
}

.input-container {
    margin-top: 20px;
}

.result-box{
    margin-top: 50px;
}

.result-container {
    background-color: #424242; /* Darker background for result container */
    color: #ffffff; /* Light text color */
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
    width: 300px;
    word-break: break-word; /* Ensure long words break into the next line */
    text-align: center;
}

@media (max-width: 600px) {
    .button {
        font-size: 0.8em; /* Smaller font size for mobile */
    }

    .result-container {
        width: 90%; /* Full width for mobile devices */
    }
}
