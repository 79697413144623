.faq-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1E0342;
    margin-top: 100px;
    padding: 20px;
    line-height: 2rem;
}

.faq-highlight{
    background-color: bisque;
    color: black;
}

.content_faq {
    margin: 0 auto;
    padding: 20px;
    color: #ffffff; /* Light text color */
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.faq-question {
    cursor: pointer;
    padding: 10px;
    width: 50rem;
    background-color: #333333; /* Darker background for questions */
    margin-bottom: 20px;
}

.faq-answer {
    padding: 10px;
    width: 50rem;
    background-color: #2a2a2a; /* Slightly lighter background for answers */
    margin-bottom: 5px;
    border-left: 3px solid #ff9800; /* Orange border for answers */
}

.faq-dropdown {
    margin-bottom: 10px;
}

.faq-question:hover {
    background-color: #444444;
}

a.instruction_anc {
    color: #ff9800;
    text-decoration: underline;
}

a.instruction_anc:hover {
    color: #ff5722;
}

@media only screen and (max-width: 1000px) {
    .faq-answer{
        width: auto;
    }
.faq-question{
    width: auto;
}
}