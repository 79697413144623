.scanner_btn{
    margin-top: 20px;
    margin-bottom: 20px;
}

.nav_info{
    color: #ff9800; /* Gradient color 1 */
    background: linear-gradient(to right, #ff9800, #ff5722); /* Gradient effect */
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: white;
}

.nav_links{
    display: flex;
    align-items: center;
}

.nav_anchor{
    margin-left: 20px;
    text-decoration: none;
    color: white;
}

@media (max-width: 600px) {
    .nav_logo{
        font-size: x-small;
    }
    .nav_links{
        font-size: x-small;
    }
}
