.terms-conditions-content {
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
    background-color: #1f1f1f; /* Dark background */
    color: #ffffff; /* Light text color */
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.terms-conditions-content h2 {
    text-align: center;
    color: #ff9800; /* Gradient color 1 */
    background: linear-gradient(to right, #ff9800, #ff5722); /* Gradient effect */
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.terms-conditions-content h3 {
    margin-top: 20px;
    color: #ff9800; /* Gradient color 1 */
    background: linear-gradient(to right, #ff9800, #ff5722); /* Gradient effect */
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.terms-conditions-content p {
    margin-bottom: 15px;
}

.terms-conditions-content a {
    color: #ff9800;
}

@media (max-width: 800px) {
    .terms-conditions-content {
        padding: 10px;
    }
}
