.qr_footer {
    display: flex;
    justify-content: space-around;
    align-items: center; /* Center items vertically */
    font-weight: bold;
    width: 100%;
    padding: 10px 0; /* Add padding for spacing */
    box-shadow: 0 0 10px #ff9800; /* Hover effect */
    background: linear-gradient(to right, #ff5722, #ff9800); /* Reverse gradient on hover */
    color: white; /* Text color */
    font-size: 14px; /* Adjust font size */
}

.footer_links {
    display: flex;
    gap: 20px; /* Spacing between links */
}

.footer_links a {
    color: inherit; /* Inherit text color */
    text-decoration: none; /* Remove underline */
    transition: color 0.3s ease; /* Smooth color transition */
}

.footer_links a:hover {
    color: #fff; /* Change text color on hover */
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .qr_footer {
        flex-direction: column-reverse; /* Stack items vertically */
        text-align: center; /* Center align text */
        padding: 20px 0; /* Increase padding for better spacing */
    }

    .footer_links {
        flex-direction: column; /* Stack links vertically */
        gap: 10px; /* Reduce spacing between links */
    }
}
