body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #121212; /* Dark background */
    color: #ffffff; /* Light text color */
}

.initial_pos {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.headerqr {
    color: #ff9800; /* Gradient color 1 */
    background: linear-gradient(to right, #ff9800, #ff5722); /* Gradient effect */
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1em;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 50px;
}

select {
    margin-top: 10px;
    padding: 8px; /* Padding for the dropdown */
    border: none;
    border-radius: 5px;
    background-color: #424242; /* Darker background for dropdown */
    color: #ffffff; /* Light text color */
    font-size: 0.9em;
    transition: all 0.3s ease;
    cursor: pointer;
}

select:hover {
    box-shadow: 0 0 10px #ff9800; /* Hover effect */
}

button {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(to right, #ff9800, #ff5722); /* Gradient effect */
    color: #ffffff;
    font-size: 0.9em;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 10px;
}

button:hover {
    box-shadow: 0 0 10px #ff9800; /* Hover effect */
    background: linear-gradient(to right, #ff5722, #ff9800); /* Reverse gradient on hover */
}

@media (max-width: 600px) {
 
    button {
        font-size: 0.8em; /* Smaller font size for mobile */
    }
}

.userinput {
    margin-top: 20px;
}

.userinput input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    width: 300px;
    font-size: 1em;
    transition: all 0.3s ease;
}

.userinput input:hover {
    box-shadow: 0 0 10px #ff9800; /* Hover effect */
}

.userdropdown {
    margin-top: 10px;
    margin-left: 5px;
}

.userdropdown select {
    padding: 10px;
    border: none;
    border-radius: 5px;
    width: 320px;
    font-size: 1em;
    background-color: #424242;
    color: #ffffff;
    transition: all 0.3s ease;
}

.userdropdown select:hover {
    box-shadow: 0 0 10px #ff9800; /* Hover effect */
}

.dropdown_flex {
    margin-bottom: 20px;
    display: flex;
}

.instruction_anc{
    color: #ff9800;
}

.instruct_flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.colorbox_style{
    margin-top: 20px;
}

@media only screen and (max-width: 700px) {
    .dropdown_flex {
      flex-direction: column;
    }
  }

button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(to right, #ff9800, #ff5722); /* Gradient effect */
    color: #ffffff;
    font-size: 1em;
    cursor: pointer;
    transition: all 0.3s ease;
}

button:hover {
    box-shadow: 0 0 10px #ff9800; /* Hover effect */
    background: linear-gradient(to right, #ff5722, #ff9800); /* Reverse gradient on hover */
}

.qr_btn_select{
    margin-bottom: 10px;
    margin-top: 10px;
}

.sendall_btn{
    margin-left: 10px;
}